import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Rahimie Rosli | Software Developer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description:
    'Rahimie Rosli | About -  Rahimie Bin Rosli is a software developer who has been involved in the creation of web applications professionally since 2019. He is based in the city of Kuala Lumpur, Malaysia.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'RAHIMIE ROSLI',
  subtitle: 'I am a FULL STACK SOFTWARE DEVELOPER',
  cta: 'About Me',
  message: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    'Hi I am Rahimie Rosli.  I have strong computer engineering base with a Bachelor Degree in Computer Science specialization in Software Engineering from Multimedia University, Cyberjaya. I been involved in the creation of web applications professionally since 2019. Currently, I am based in the city of Kuala Lumpur, Malaysia.',
  paragraphTwo:
    'I am really passionate software developer that is eager to pick up new skills, knowledge and challenge. I believe in the power of technology to make lives better. Currently, I am a full stack software developer at Cybersolution Technologies Sdn Bhd, soaking up as much knowledge and practice as I can in an attempt to perfect my skill. I have experience using Java Springboot, Javascript, SQL, Node.js, Adobe XD, ReactJS and Angular Framework.',
  paragraphThree:
    'I also involve in implementing computer system requirements by defining and analyzing system problems; designing and testing standards and solutions. In short, I have experienced and practiced in all software development life cycle from planning, requirement gathering(Meet User/Client), requirement analysis (User Requirement Specification), designing(System Requirement Specification), building(Coding Time), testing(User Acceptance Test) and maintaining.  Do direct message me on my LinkedIn Profile or Github page to know more.',
  resume: 'profile1.jpg', // if no resume, the button will not show up
  // resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project3.jpg',
    title: 'Integrated Financial Sytem',
    info:
      'A web application  to support management of public sector budgetary, financial, and accounting operations and promote better public financial management (PFM) with a centralized registry of public sector revenues and expenditures. ',
    info2:
      '* JAVA , SPRINGBOOT FRAMEWORK, MYSQL, HTML/CSS, JAVASCRIPT/TYPESCRIPT, REACT FRAMEWORK, ANGULAR FRAMEWORK, BOOTSTRAP, ADOBE XD*',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project3.jpg',
    title: 'Human Resources Management System',
    info:
      'A web application that track, calculate and manage leaves, salary, recruitment, performance and labor relation.',
    info2: '* HTML/CSS, JAVASCRIPT/TYPESCRIPT, ANGULAR FRAMEWORK, BOOTSTRAP*',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
];

// SIDE PROJECT DATA
export const sideProjectsData = [
  {
    id: nanoid(),
    img: 'project1.jpg',
    title: 'FooDo',
    info:
      'A mobile application that allows users to fight food waste problem. This application not only can solve the food waste problem but also help hunger to find food.',
    info2: '* ANDROID , JAVA , XML , FIREBASE*',
    url:
      'https://www.youtube.com/watch?v=sSbtpmru-iY&rel=0&utm_source=broadcast&utm_medium=email&utm_campaign=Transactional-Publish-success&ab_channel=REMIEROSLI',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'rahimie.business@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://www.instagram.com/rahimierosli/',
    },
    {
      id: nanoid(),
      name: 'facebook',
      url: 'https://www.instagram.com/rahimierosli/',
    },
    {
      id: nanoid(),
      name: 'instagram',
      url: 'https://www.instagram.com/rahimierosli/',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.instagram.com/rahimierosli/',
    },
    // {
    //   id: nanoid(),
    //   name: 'github',
    //   url: '',
    // },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
